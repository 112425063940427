import React from "react";
import {Helmet} from "react-helmet";

import HeaderV2 from "../components/HeaderV2/HeaderV2";
import Map from "../components/GoogleMap";
import Footer from "../components/Footer/Footer";
import ContactUsBtn from "../components/ContactUsBtn";


import "../../styles/Aboutus.scss";
import SEO from "../components/SEO";




const About: React.FC = () => {
    return (
        <div>
            <SEO title={" About us - QCTesting"} description={"Information about Qctesting"} url={"www.qctesting.com.au/AboutUs"}/>
            <div>
                <HeaderV2/>
                <div className={"googleMap"}>
                    <Map/>
                </div>
                <div className={"information"}>
                    <div>
                        <h1> Nata accredited testing </h1>
                        <p> We are a Nata accredited testing laboratory, this means we produce reliable test results </p>
                    </div>
                    <div>
                        <h1> Where we are based and our service area </h1>
                        <p> We are base on the Sunshine Coast. We can service anywhere, we've done testing from Gympie to Toogoolawah. </p>
                    </div>
                    <div>
                        <h1> Where we are locate and contact information</h1>
                        <p> We are located at <mark id={"highlight"}> 11-21 Cordwell Rd, Yandina QLD 4561</mark> or you can contact us at <mark id={"highlight"}> 0408623377 </mark></p>
                        <p> You can also request a quote clicking the button or quick call by clicking the phone. </p>
                    </div>

                </div>
                <Footer/>

                <ContactUsBtn className={"contactBtnPosition"} redirect={"Tel:0408623377"}/>
            </div>
        </div>
    );
};

export default About;
